<!--
 * @Author: Libra
 * @Date: 2021-05-24 10:14:30
 * @LastEditTime: 2023-04-24 16:20:19
 * @LastEditors: Libra
 * @Description:在线考试邀请页面
 * @FilePath: /stone-exam-ui/src/views/common/ExamInvite.vue
-->
<template>
  <div class="invite-container">
    <el-dialog
      title="放弃参加"
      :visible.sync="dialogFormVisible"
      class="dialog"
      center
    >
      <el-form :model="refuse">
        <el-form-item label="放弃原因" class="form-item">
          <el-select v-model="refuse.reason" placeholder="请选择放弃原因">
            <el-option label="已拿到offer" value="已拿到offer"></el-option>
            <el-option label="时间不合适" value="时间不合适"></el-option>
            <el-option label="没有网络环境" value="没有网络环境"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原因描述">
          <el-input
            type="textarea"
            placeholder="亲，请告知我们放弃的原因吧，以便我们改进工作，为大家提供更好的服务！"
            :rows="4"
            v-model="refuse.reasonDetail"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <CustomButton
          class="gap"
          title="取消"
          :clickMethod="() => (dialogFormVisible = false)"
          style="margin-right: 10px"
        ></CustomButton>
        <CustomButton
          class="gap"
          title="确认"
          :isPlain="false"
          :clickMethod="refuseConfirm"
        ></CustomButton>
      </div>
    </el-dialog>
    <el-dialog
      title="手机微信扫码"
      :visible.sync="dialogQrCodeVisible"
      class="dialog"
    >
    <div class="qr-con">
      <vue-qr
            :size="800"
            style="width: 200px;height: 200px;transform: translateY(0);max-width: 300px"
            :text="codeStr"
          ></vue-qr>
          <span class="qr-des">请将二维码截图保存在相册里，打开微信扫码界面，点右下角相册图标，选中截图完成扫码。</span>
    </div>
      <div slot="footer" class="dialog-footer" style="justify-content: center">
        <CustomButton
          title="我知道了"
          :clickMethod="() => (dialogQrCodeVisible = false)"
        ></CustomButton>
      </div>
    </el-dialog>
    <span
class="title"
      >【{{ form.companyShortName }}】
      {{ form.scenario || "在线考试" }} 邀请</span
    >
    <div class="subtitle-container">
      <div class="iconfont-container">
        <i class="iconfont icongerenxinxiicon-"></i>
      </div>
      <span class="subtitle">考生信息</span>
    </div>
    <div class="column">
      <div class="left">姓名</div>
      <div class="right bold">{{ form.realName }}</div>
    </div>
    <div class="column">
      <div class="left">手机号</div>
      <div class="right">{{ form.mobile }}</div>
    </div>
    <div class="column">
      <div class="left">E-mail</div>
      <div class="right">{{ form.email }}</div>
    </div>
    <div class="subtitle-container">
      <div class="iconfont-container">
        <i class="iconfont iconzhanghao"></i>
      </div>
      <span class="subtitle">考试账号</span>
    </div>
    <div class="column">
      <div class="left">开始时间</div>
      <div class="right bold">
        {{ form.startAt.slice(0, -3) }}
        {{ getweekday(form.startAt.slice(0, -3)) }}
      </div>
    </div>
    <div class="column">
      <div class="left" :style="`height:${form.device===4 ? '104px': '64px'}`">考试入口</div>
      <div
        class="right vertical"
        style="height:64px"
        v-if="form.device === 2"
      >
        <span>
          <a
            style="color: #f35a5a; font-weight: bold"
            href="https://www.iguokao.com/client"
            >https://www.iguokao.com/client
          </a>
        </span>
        <span
style="display: block; color: #666;"
          >(请在电脑上打开上方客户端下载路径，下载并安装客户端软件)</span
        >
      </div>
      <div
        class="right vertical"
        style="height: 64px;"
        v-else-if="form.device === 1"
      >
        <span>
          <a
style="color: #f35a5a; font-weight: bold"
:href="form.loginUrl"
            >{{ form.loginUrl }}
          </a>
        </span>
        <span style="display: block; color: #666;">(请使用电脑打开链接)</span>
      </div>
      <div class="right vertical" style="height: 64px;" v-else-if="form.device === 3">
        <span>
          <a
@click="dialogQrCodeVisible= true"
style="color: #f35a5a; font-weight: bold"
            >&gt;&gt; 点击查看二维码 &lt;&lt;

          </a>
        </span>
        <span style="display: block; color: #666;">（请使用手机微信扫码作答）</span>
      </div>
      <div class="right vertical" style="height: 104px;" v-else-if="form.device === 4">
        <div>
          <span>
            1.
          <a
@click="dialogQrCodeVisible= true"
style="color: #f35a5a; font-weight: bold"
            >&gt;&gt; 点击查看二维码 &lt;&lt;

          </a>
        </span>
        <span style="display: block; color: #666;">（请使用手机微信扫码作答）</span>
        </div>
        <div>
          <span>
            2.
          <a
style="color: #f35a5a; font-weight: bold"
:href="form.loginUrl"
            >{{ form.loginUrl }}
          </a>
        </span>
        <span style="display: block; color: #666;">&nbsp;(请使用电脑打开链接作答)</span>
        </div>
      </div>
    </div>
    <div class="column" v-if="form.device !== 3">
      <div class="left">登录账号</div>
      <div class="right">{{ form.loginName }}</div>
    </div>
    <div class="column" v-if="form.device !== 3">
      <div class="left">登录密码</div>
      <div class="right bold">{{ form.loginPassword }}</div>
    </div>
    <div class="column" style="color: #cb2a1d;">
      <div class="left">确认情况</div>
      <div class="right btn_container">
        <button class="btn btn-confirm pc" @click="confirm">确认参加</button>
        <button
          class="btn pc"
          v-if="isAllowRefuse"
          @click="dialogFormVisible = true"
        >
          放弃参加
        </button>
        <button v-if="isAllowRefuse" class="btn pc" @click="laterConfirm">
          稍后确认
        </button>
        <div class="state">
          （{{ confirmStateText[confirmState] || "未确认" }}）
        </div>
      </div>
    </div>
    <div class="btn-mobile">
      <div class="btn-container">
        <button class="btn" @click="confirm">确认参加</button>
        <button
          class="btn"
          v-if="isAllowRefuse"
          @click="dialogFormVisible = true"
        >
          放弃参加
        </button>
        <button v-if="isAllowRefuse" class="btn" @click="laterConfirm">
          稍后确认
        </button>
      </div>
    </div>
    <div class="tips" v-html="strReplace(form.qualificationsNote)"></div>
    <el-dialog title="确认状态" :visible.sync="dialogVisible" class="confirm">
      <div class="content">
        <i class="iconfont iconduigou1"></i>
        <div class="right">
          <span class="title">已收到反馈！</span>
          <span class="result">{{
            confirmDialogState === 10
              ? "已审阅，稍后确认"
              : confirmDialogState === 20
              ? "已放弃参加"
              : "已确认参加"
          }}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Api from '@/api/api'
import CustomButton from '@/components/CustomButton'
import Clipboard from 'clipboard'
import VueQr from 'vue-qr'

export default {
  data() {
    return {
      refuse: {
        reason: '',
        reasonDetail: ''
      },
      form: {
        realName: '',
        mobile: '',
        email: '',
        jobDuration: 0,
        startAt: '',
        endAt: '',
        loginUrl: '',
        loginName: '',
        loginPassword: '',
        device: 1
      },
      codeStr: '',
      candidateUuid: '',
      dialogFormVisible: false,
      dialogVisible: false,
      isAllowRefuse: true,
      confirmState: '',
      confirmDialogState: 30,
      dialogQrCodeVisible: false,
      confirmStateText: {
        30: '已确认参加',
        20: '已放弃参加',
        10: '稍后确认'
      }
    }
  },
  components: {
    CustomButton,
    VueQr
  },
  mounted() {
    this.copyUrl()
  },
  created() {
    this.candidateUuid = Object.keys(this.$route.query)[0]
    // 是否允许放弃参加
    this.isAllowRefuse = this.$route.query.isAllowRefuse === 'true'
    this.getCandidateInfo(this.candidateUuid)
  },
  methods: {
    async getCandidateInfo(candidateUuid) {
      const res = await Api.getCandidateInfo(candidateUuid)
      if (res.code === 0) {
        this.confirmState = res.data.confirmState
        this.form = res.data
        document.title = `${this.form.companyShortName}${this.form.scenario ||
          '在线考试'}系统-国考云`
        if (this.form.device === 3 || this.form.device === 4) {
          this.getQrToken()
        }
      }
    },
    // 稍后确认
    async laterConfirm() {
      const res = await Api.candidateConfirm(
        `candidateUuid=${this.candidateUuid}&confirmState=10`
      )
      if (res.code === 0) {
        this.dialogVisible = true
        this.confirmDialogState = 10
        this.getCandidateInfo(this.candidateUuid)
      }
    },
    // 放弃参加
    async refuseConfirm() {
      this.dialogFormVisible = false
      const res = await Api.candidateConfirm(
        `candidateUuid=${this.candidateUuid}&confirmState=20&refuseMessage=${this.refuse.reasonDetail}&refuseReason=${this.refuse.reason}`
      )
      if (res.code === 0) {
        this.dialogVisible = true
        this.confirmDialogState = 20
        this.getCandidateInfo(this.candidateUuid)
      }
    },
    // 确认参加
    async confirm() {
      const res = await Api.candidateConfirm(
        `candidateUuid=${this.candidateUuid}&confirmState=30`
      )
      if (res.code === 0) {
        this.dialogVisible = true
        this.confirmDialogState = 30
        this.getCandidateInfo(this.candidateUuid)
      }
    },
    // 字符串替换
    strReplace(str) {
      if (!str) return
      const s = str.replace(/&lt;/g, '<')
      return s.replace(/&gt;/g, '>')
    },
    getweekday(date) {
      var weekArray = [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六'
      ]
      var week = weekArray[new Date(date).getDay()]
      return week
    },
    // 复制链接
    copyUrl() {
      const _this = this
      const clipboard = new Clipboard('.copy-btn') // 这里括号里填写上面点击事件绑定的class名
      clipboard.on('success', e => {
        // 复制成功，提示根据自己项目实际使用的UI来写
        _this.$message.success('复制成功')
        // 释放内存
        // clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制，提示根据自己项目实际使用的UI来写
        _this.$message.error('该浏览器不支持自动复制')
        // 释放内存
        // clipboard.destroy()
      })
    },
    async getQrToken() {
      const res = await Api.getQrToken(this.candidateUuid)
      if (res.code === 0) {
        const env = process.env.NODE_ENV
        // const env = 'beta'
        const host = 'https://exam-file.iguokao.com/index?secret='
        this.codeStr = `${host}${res.data.token}&env=${env}`
      } else {
        this.$message.error('获取二维码失败')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 1200px) {
  .invite-container {
    width: 1070px;
  }
}
@media (min-width: 1200px) {
  .invite-container {
    width: 1200px;
  }
}
@media (min-width: 768px) {
  .invite-container {
    width: 750px;
  }
}
@media (max-width: 768px) {
  .invite-container {
    width: 100vw;
  }
}
.invite-container {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  padding: 20px 20px 60px 20px;
  margin: 0 auto;
  position: relative;
  .qr-con {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .qr-des {
      width: 300px;
      margin-top: 20px;
      font-weight: bold;
    }
  }
  .confirm {
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    .iconfont {
      font-size: 60px;
      color: #cb2a1d;
      margin-right: 20px;
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      .title {
        font-size: 14px;
      }
      .result {
        font-size: 18px;
        font-weight: bold;
        margin-top: 10px;
      }
    }
  }
  .el-form-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    ::v-deep .el-form-item__content {
      flex: 1;
      width: 100%;
    }
    .el-select {
      width: 100%;
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  ::v-deep .el-select .el-input.is-focus .el-input__inner {
    border-color: #cb2a1d;
  }
  ::v-deep .el-select .el-input__inner:focus {
    border-color: #cb2a1d;
  }
  ::v-deep .el-textarea__inner:focus {
    border-color: #cb2a1d;
  }
  ::v-deep .el-dialog {
    width: 450px;
    @media (max-width: 768px) {
      width: 90%;
    }
  }
  .btn-mobile {
    display: none;
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    .btn-container {
      display: flex;
      justify-content: center;
      align-items: center;
      .btn {
        flex: 1;
        padding: 15px 0px;
        cursor: pointer;
        background-color: #cb2a1d;
        border: none;
        outline: none;
        color: #fff;
        font-size: 12px;
        &:nth-of-type(2) {
          margin: 0 1px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .btn-mobile {
      display: block;
    }
  }
  .title {
    font-size: 20px;
    font-weight: bold;
  }
  .subtitle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    .iconfont-container {
      width: 25px;
      height: 25px;
      background-color: #f35a5a;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      .iconfont {
        font-size: 16px;
        color: #fff;
      }
      .iconzhanghao {
        font-size: 24px;
      }
    }
    .subtitle {
      font-weight: bold;
      margin-left: 5px;
      font-size: 16px;
    }
  }
  .column {
    margin: 5px 0px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    @media (max-width: 768px) {
      .pc {
        display: none;
      }
    }
    .bold {
      font-weight: bold;
    }
    /**适配一下iphone5 */
    @media (max-width: 320px) {
      font-size: 12px;
    }
    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 10px 10px 10px;
      width: 150px;
      height: 41px;
      margin-right: 10px;
      background-color: rgb(245, 245, 245);
      @media (max-width: 768px) {
        width: 120px;
      }
    }
    .right {
      padding: 10px 0 10px 10px;
      background-color: rgb(245, 245, 245);
      flex: 1;
      min-height: 16px;
      height: 41px;
      .btn {
        padding: 5px 10px;
        border: 1px solid #cb2a1d;
        color: #cb2a1d;
        transition: all 0.6s;
        cursor: pointer;
        margin-right: 10px;
        font-size: 12px;
        &:hover {
          background-color: #cb2a1d;
          color: #fff;
          transition: all 0.6s;
        }
      }
      .btn-confirm {
        background-color: #cb2a1d;
        color: #fff;
      }
    }
    .btn_container {
      padding: 0 0 0 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .vertical {
      overflow: auto;
      padding: 0 0 0 10px;
      font-size: 12px;
      height: 41px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      @media (max-width: 768px) {
        padding: 0 0 0 10px;
        span {
          margin-right: 10px;
        }
      }
    }
  }
  .tips {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px dashed red;
    padding: 20px;
    margin-top: 20px;
    box-sizing: border-box;
    .tip-title {
      color: #cb2a1d;
      margin: 10px 0;
      font-size: 14px;
      position: relative;
      margin-left: 10px;
      &::before {
        position: absolute;
        top: 0;
        left: -10px;
        content: "";
        width: 3px;
        height: 16px;
        background-color: #cb2a1d;
      }
    }
    .tip-content {
      line-height: 2.5;
      font-size: 12px;
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #cb2a1d;
    width: 100%;
    line-height: 2;
    font-size: 14px;
    margin-top: 40px;
  }
}
</style>
