var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "invite-container" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "放弃参加",
            visible: _vm.dialogFormVisible,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.refuse } },
            [
              _c(
                "el-form-item",
                { staticClass: "form-item", attrs: { label: "放弃原因" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择放弃原因" },
                      model: {
                        value: _vm.refuse.reason,
                        callback: function ($$v) {
                          _vm.$set(_vm.refuse, "reason", $$v)
                        },
                        expression: "refuse.reason",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "已拿到offer", value: "已拿到offer" },
                      }),
                      _c("el-option", {
                        attrs: { label: "时间不合适", value: "时间不合适" },
                      }),
                      _c("el-option", {
                        attrs: { label: "没有网络环境", value: "没有网络环境" },
                      }),
                      _c("el-option", {
                        attrs: { label: "其他", value: "其他" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "原因描述" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder:
                        "亲，请告知我们放弃的原因吧，以便我们改进工作，为大家提供更好的服务！",
                      rows: 4,
                    },
                    model: {
                      value: _vm.refuse.reasonDetail,
                      callback: function ($$v) {
                        _vm.$set(_vm.refuse, "reasonDetail", $$v)
                      },
                      expression: "refuse.reasonDetail",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("CustomButton", {
                staticClass: "gap",
                staticStyle: { "margin-right": "10px" },
                attrs: {
                  title: "取消",
                  clickMethod: () => (_vm.dialogFormVisible = false),
                },
              }),
              _c("CustomButton", {
                staticClass: "gap",
                attrs: {
                  title: "确认",
                  isPlain: false,
                  clickMethod: _vm.refuseConfirm,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: { title: "手机微信扫码", visible: _vm.dialogQrCodeVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogQrCodeVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "qr-con" },
            [
              _c("vue-qr", {
                staticStyle: {
                  width: "200px",
                  height: "200px",
                  transform: "translateY(0)",
                  "max-width": "300px",
                },
                attrs: { size: 800, text: _vm.codeStr },
              }),
              _c("span", { staticClass: "qr-des" }, [
                _vm._v(
                  "请将二维码截图保存在相册里，打开微信扫码界面，点右下角相册图标，选中截图完成扫码。"
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "justify-content": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("CustomButton", {
                attrs: {
                  title: "我知道了",
                  clickMethod: () => (_vm.dialogQrCodeVisible = false),
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("span", { staticClass: "title" }, [
        _vm._v(
          "【" +
            _vm._s(_vm.form.companyShortName) +
            "】 " +
            _vm._s(_vm.form.scenario || "在线考试") +
            " 邀请"
        ),
      ]),
      _vm._m(0),
      _c("div", { staticClass: "column" }, [
        _c("div", { staticClass: "left" }, [_vm._v("姓名")]),
        _c("div", { staticClass: "right bold" }, [
          _vm._v(_vm._s(_vm.form.realName)),
        ]),
      ]),
      _c("div", { staticClass: "column" }, [
        _c("div", { staticClass: "left" }, [_vm._v("手机号")]),
        _c("div", { staticClass: "right" }, [_vm._v(_vm._s(_vm.form.mobile))]),
      ]),
      _c("div", { staticClass: "column" }, [
        _c("div", { staticClass: "left" }, [_vm._v("E-mail")]),
        _c("div", { staticClass: "right" }, [_vm._v(_vm._s(_vm.form.email))]),
      ]),
      _vm._m(1),
      _c("div", { staticClass: "column" }, [
        _c("div", { staticClass: "left" }, [_vm._v("开始时间")]),
        _c("div", { staticClass: "right bold" }, [
          _vm._v(
            " " +
              _vm._s(_vm.form.startAt.slice(0, -3)) +
              " " +
              _vm._s(_vm.getweekday(_vm.form.startAt.slice(0, -3))) +
              " "
          ),
        ]),
      ]),
      _c("div", { staticClass: "column" }, [
        _c(
          "div",
          {
            staticClass: "left",
            style: `height:${_vm.form.device === 4 ? "104px" : "64px"}`,
          },
          [_vm._v("考试入口")]
        ),
        _vm.form.device === 2
          ? _c(
              "div",
              {
                staticClass: "right vertical",
                staticStyle: { height: "64px" },
              },
              [
                _vm._m(2),
                _c(
                  "span",
                  { staticStyle: { display: "block", color: "#666" } },
                  [
                    _vm._v(
                      "(请在电脑上打开上方客户端下载路径，下载并安装客户端软件)"
                    ),
                  ]
                ),
              ]
            )
          : _vm.form.device === 1
          ? _c(
              "div",
              {
                staticClass: "right vertical",
                staticStyle: { height: "64px" },
              },
              [
                _c("span", [
                  _c(
                    "a",
                    {
                      staticStyle: { color: "#f35a5a", "font-weight": "bold" },
                      attrs: { href: _vm.form.loginUrl },
                    },
                    [_vm._v(_vm._s(_vm.form.loginUrl) + " ")]
                  ),
                ]),
                _c(
                  "span",
                  { staticStyle: { display: "block", color: "#666" } },
                  [_vm._v("(请使用电脑打开链接)")]
                ),
              ]
            )
          : _vm.form.device === 3
          ? _c(
              "div",
              {
                staticClass: "right vertical",
                staticStyle: { height: "64px" },
              },
              [
                _c("span", [
                  _c(
                    "a",
                    {
                      staticStyle: { color: "#f35a5a", "font-weight": "bold" },
                      on: {
                        click: function ($event) {
                          _vm.dialogQrCodeVisible = true
                        },
                      },
                    },
                    [_vm._v(">> 点击查看二维码 << ")]
                  ),
                ]),
                _c(
                  "span",
                  { staticStyle: { display: "block", color: "#666" } },
                  [_vm._v("（请使用手机微信扫码作答）")]
                ),
              ]
            )
          : _vm.form.device === 4
          ? _c(
              "div",
              {
                staticClass: "right vertical",
                staticStyle: { height: "104px" },
              },
              [
                _c("div", [
                  _c("span", [
                    _vm._v(" 1. "),
                    _c(
                      "a",
                      {
                        staticStyle: {
                          color: "#f35a5a",
                          "font-weight": "bold",
                        },
                        on: {
                          click: function ($event) {
                            _vm.dialogQrCodeVisible = true
                          },
                        },
                      },
                      [_vm._v(">> 点击查看二维码 << ")]
                    ),
                  ]),
                  _c(
                    "span",
                    { staticStyle: { display: "block", color: "#666" } },
                    [_vm._v("（请使用手机微信扫码作答）")]
                  ),
                ]),
                _c("div", [
                  _c("span", [
                    _vm._v(" 2. "),
                    _c(
                      "a",
                      {
                        staticStyle: {
                          color: "#f35a5a",
                          "font-weight": "bold",
                        },
                        attrs: { href: _vm.form.loginUrl },
                      },
                      [_vm._v(_vm._s(_vm.form.loginUrl) + " ")]
                    ),
                  ]),
                  _c(
                    "span",
                    { staticStyle: { display: "block", color: "#666" } },
                    [_vm._v(" (请使用电脑打开链接作答)")]
                  ),
                ]),
              ]
            )
          : _vm._e(),
      ]),
      _vm.form.device !== 3
        ? _c("div", { staticClass: "column" }, [
            _c("div", { staticClass: "left" }, [_vm._v("登录账号")]),
            _c("div", { staticClass: "right" }, [
              _vm._v(_vm._s(_vm.form.loginName)),
            ]),
          ])
        : _vm._e(),
      _vm.form.device !== 3
        ? _c("div", { staticClass: "column" }, [
            _c("div", { staticClass: "left" }, [_vm._v("登录密码")]),
            _c("div", { staticClass: "right bold" }, [
              _vm._v(_vm._s(_vm.form.loginPassword)),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "column", staticStyle: { color: "#cb2a1d" } }, [
        _c("div", { staticClass: "left" }, [_vm._v("确认情况")]),
        _c("div", { staticClass: "right btn_container" }, [
          _c(
            "button",
            { staticClass: "btn btn-confirm pc", on: { click: _vm.confirm } },
            [_vm._v("确认参加")]
          ),
          _vm.isAllowRefuse
            ? _c(
                "button",
                {
                  staticClass: "btn pc",
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = true
                    },
                  },
                },
                [_vm._v(" 放弃参加 ")]
              )
            : _vm._e(),
          _vm.isAllowRefuse
            ? _c(
                "button",
                { staticClass: "btn pc", on: { click: _vm.laterConfirm } },
                [_vm._v(" 稍后确认 ")]
              )
            : _vm._e(),
          _c("div", { staticClass: "state" }, [
            _vm._v(
              " （" +
                _vm._s(_vm.confirmStateText[_vm.confirmState] || "未确认") +
                "） "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "btn-mobile" }, [
        _c("div", { staticClass: "btn-container" }, [
          _c("button", { staticClass: "btn", on: { click: _vm.confirm } }, [
            _vm._v("确认参加"),
          ]),
          _vm.isAllowRefuse
            ? _c(
                "button",
                {
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = true
                    },
                  },
                },
                [_vm._v(" 放弃参加 ")]
              )
            : _vm._e(),
          _vm.isAllowRefuse
            ? _c(
                "button",
                { staticClass: "btn", on: { click: _vm.laterConfirm } },
                [_vm._v(" 稍后确认 ")]
              )
            : _vm._e(),
        ]),
      ]),
      _c("div", {
        staticClass: "tips",
        domProps: {
          innerHTML: _vm._s(_vm.strReplace(_vm.form.qualificationsNote)),
        },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "confirm",
          attrs: { title: "确认状态", visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "content" }, [
            _c("i", { staticClass: "iconfont iconduigou1" }),
            _c("div", { staticClass: "right" }, [
              _c("span", { staticClass: "title" }, [_vm._v("已收到反馈！")]),
              _c("span", { staticClass: "result" }, [
                _vm._v(
                  _vm._s(
                    _vm.confirmDialogState === 10
                      ? "已审阅，稍后确认"
                      : _vm.confirmDialogState === 20
                      ? "已放弃参加"
                      : "已确认参加"
                  )
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "subtitle-container" }, [
      _c("div", { staticClass: "iconfont-container" }, [
        _c("i", { staticClass: "iconfont icongerenxinxiicon-" }),
      ]),
      _c("span", { staticClass: "subtitle" }, [_vm._v("考生信息")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "subtitle-container" }, [
      _c("div", { staticClass: "iconfont-container" }, [
        _c("i", { staticClass: "iconfont iconzhanghao" }),
      ]),
      _c("span", { staticClass: "subtitle" }, [_vm._v("考试账号")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c(
        "a",
        {
          staticStyle: { color: "#f35a5a", "font-weight": "bold" },
          attrs: { href: "https://www.iguokao.com/client" },
        },
        [_vm._v("https://www.iguokao.com/client ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }